import React, { useRef } from "react";

const VideoPlayer = ({ src, poster, onClose, showCloseButton = true, showControls = true }) => {
  const videoRef = useRef(null);

  const handleFullScreen = () => {
    if (videoRef.current) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        videoRef.current.requestFullscreen().catch(err => {
          console.error("Failed to enable full-screen mode", err);
        });
      }
    }
  };

  return (
    <div style={{
      position: "fixed", top: 0, left: 0,
      width: "100%", height: "100%", background: "black", zIndex: 1000
    }}>
      {showCloseButton && <button 
        onClick={onClose} 
        style={{
          position: "absolute", top: "10px", right: "10px", 
          background: "red", color: "white", border: "none", 
          padding: "10px", cursor: "pointer", zIndex: 1001
        }}
      >
        X
      </button>}
      <video
        ref={videoRef}
        src={src}
        poster={poster}
        autoPlay
        muted
        playsInline
        loop
        preload="auto"
        controls={showControls}
        style={{ width: "100%", height: "100%" }}
        onDoubleClick={handleFullScreen}
      />
    </div>
  );
};

export default VideoPlayer;