import React from 'react';
import { SiteProvider } from './SiteContext';

const withSite = (Component, site) => {
  return (props) => (
    <SiteProvider site={site}>
      <Component {...props} />
    </SiteProvider>
  );
};

export default withSite;