import { useState, useEffect } from "react";
import { SCREEN_MAP, SITE_TAG } from "../App";
import { supabase } from "../supabase";

const Settings = () => {
    const [selectedPage, setSelectedPage] = useState('live');

    const handleChange = (event) => {
        const screenTag = event.target.value;
        const { data, error } = supabase
            .from('screen_configuration')
            .update([{ screen_tag: screenTag}])
            .eq('site', SITE_TAG)
            .then(console.log);
        
        if (!error) setSelectedPage(screenTag);
    };

    useEffect(() => {
        const fetchScreenConfig = async () => {
            const { data, error } = await supabase
                .from("screen_configuration")
                .select("screen_tag")
                .eq("site", SITE_TAG).single();

            if (error) {
                console.error(error);
                return;
            }

            if (!data) {
                console.error("No screen configuration found for site", SITE_TAG);
                return;
            }

            // Only set if it's one of the user setting screens, otherwise default to 'live'
            const screenTag = data['screen_tag'];
            setSelectedPage(Object.keys(SCREEN_MAP).includes(screenTag) ? screenTag : 'live');
        };

        fetchScreenConfig();
    }, []);

    return (
        <div className="container mx-auto px-4">
            <div>
                <h1>Settings</h1>
            </div>
            <select value={selectedPage} onChange={handleChange}>
                {Object.keys(SCREEN_MAP).map((screen, index) => (
                    <option key={index} value={screen}>{screen}</option>
                ))}
            </select>
        </div>
    );
}

export default Settings;