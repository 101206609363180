import React, { useState, useEffect } from "react";
import { supabase } from "../supabase";
import VideoPlayer from "../Components/VideoPlayer";

const VideoBrowser = () => {
  const [videos, setVideos] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [tagMapping, setTagMapping] = useState({});

  // Fetch video data from the "wcn" table in Supabase  
  useEffect(() => {
    const fetchVideos = async () => {
      const { data, error } = await supabase.from("wcn").select("*").order("start_time", { ascending: false });
      if (error) {
        console.error("Error fetching videos:", error);
      } else {
        
        setVideos(data || []);
        console.log(data)
      }
    };
    fetchVideos();
  }, []);

  // Fetch tags from the "tags" table in Supabase
  useEffect(() => {
    const fetchTags = async () => {
      const { data, error } = await supabase.from("tags").select("*");
      if (error) {
        console.error("Error fetching tags:", error);
      } else {
        const mapping = data.reduce((acc, tag) => {
          acc[tag.tag] = tag.name;
          return acc;
        }, {});
        setTagMapping(mapping);

        console.log("Mapping: ", mapping)
      }
    };
    fetchTags();
  }, []);

  // Translate tags to names
  const translateTags = (tags) => {
    try {
      const parsedTags = JSON.parse(tags);
      return parsedTags.map(tag => tagMapping[tag] || tag).join(", ");
    } catch (error) {
      console.error("Error parsing tags:", error);
      return tags;
    }
  };

  // Filter videos based on the search query
  const filteredVideos = videos.filter(video =>
    (video.segment_id || "").toLowerCase().includes(searchQuery.toLowerCase()) ||
    (video.type || "").toLowerCase().includes(searchQuery.toLowerCase()) ||
    (translateTags(video.tags) || "").toLowerCase().includes(searchQuery.toLowerCase()) ||
    (video.start_time || "").toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div style={{ padding: "20px" }}>
      <h1>Video Browser</h1>
      <input
        type="text"
        placeholder="Search videos..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{ padding: "10px", width: "100%", marginBottom: "20px" }}
      />
      {selectedVideo && (
        <VideoPlayer
          src={selectedVideo.url}
          onClose={() => setSelectedVideo(null)}
          showControls={true}
        />
      )}
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
            <tr>
                <th style={{ border: "1px solid #ccc", padding: "8px" }}>Segment ID</th>
                <th style={{ border: "1px solid #ccc", padding: "8px" }}>Segment Type</th>
                <th style={{ border: "1px solid #ccc", padding: "8px" }}>Tags/Riders</th>
                <th style={{ border: "1px solid #ccc", padding: "8px" }}>Start Time</th>
                <th style={{ border: "1px solid #ccc", padding: "8px" }}>End Time</th>
            </tr>
        </thead>
        <tbody>
          {filteredVideos.map(video => (
            <tr
              key={video.segment_id}
              style={{ cursor: "pointer", border: "1px solid #ccc" }}
              onClick={() => setSelectedVideo(video)}
            >
              <td style={{ padding: "8px" }}>{video.segment_id}</td>
              <td style={{ padding: "8px" }}>{video.type}</td>
              <td style={{ padding: "8px" }}>{translateTags(video.tags)}</td>
              <td style={{ padding: "8px" }}>{new Date(video.start_time).toISOString().slice(0, 19).replace("T", " ")}</td>
              <td style={{ padding: "8px" }}>{new Date(video.end_time).toISOString().slice(0, 19).replace("T", " ")}</td> 
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default VideoBrowser;