import React, { useState, useEffect, useRef } from "react";
import { supabase } from "../supabase";
import VideoPlayer from "../Components/VideoPlayer";

const VideoAutoplayLast = () => {
  const [latestVideo, setLatestVideo] = useState(null);
  const playerRef = useRef(null);

  useEffect(() => {
    const fetchLatestVideo = async () => {
      const { data, error } = await supabase
        .from("wcn")
        .select("*")
        .order("start_time", { ascending: false })
        .limit(1);
      if (error) {
        console.error("Error fetching latest video:", error);
      } else if (data && data.length > 0) {
        setLatestVideo(data[0]);
      }
    };
    fetchLatestVideo();
  }, []);

  const handleOnLoaded = () => {
    if (playerRef.current) {
      playerRef.current.requestFullscreen().catch(err => {
        console.error("Fullscreen request failed:", err);
      });
    }   
  };

  return (
    <div style={{ padding: "20px", background: "black", height: "100vh" }}>
      {latestVideo ? (
        <VideoPlayer
            src={latestVideo.url}
            controls={false}
            showCloseButton={false}
            showControls={false}
        />
      ) : (
        <p style={{ color: "white" }}>Loading latest video...</p>
      )}
    </div>
  );
};

export default VideoAutoplayLast;