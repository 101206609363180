import React, { useState, useEffect } from "react";
import { supabase } from "../supabase"; // Import Supabase client
import "./Home.css";

const TeamPursuit = () => {
  const [lapTimes, setLapTimes] = useState([]);
  const [eventFilter, setEventFilter] = useState("first_passage"); // Default event filter
  const [tags, setTags] = useState([]); // For storing tags with names and categories
  const [selectedTags, setSelectedTags] = useState([]); // For selected tags
  const [selectedCategories, setSelectedCategories] = useState([]); // For selected categories
  const [filters, setFilters] = useState({ tag: "", name: "", category: "" });
  const [visibleColumns, setVisibleColumns] = useState({
    corner_split: true,
    straight_split: true,
    sprint_split: true,
  });

  useEffect(() => {

    const fetchTags = async () => {
      const { data, error } = await supabase.from("tags").select("*");
      if (error) {
        console.error("Error fetching tags:", error);
        return;
      }
      setTags(data || []);
    };

    const fetchLapTimes = async () => {
      const { data, error } = await supabase.from("laptimes").select("*");
      if (error) {
        console.error("Error fetching lap times:", error);
        return;
      }

      processLapTimes(data);
    };

    const subscribeToLapTimes = () => {
      const channel = supabase
        .channel("laptimes")
        .on(
          "postgres_changes",
          { event: "*", schema: "public", table: "laptimes" },
          (payload) => {
            fetchLapTimes(); // Re-fetch data on changes
          }
        )
        .subscribe();

      return () => {
        supabase.removeChannel(channel);
      };
    };

    fetchTags();
    fetchLapTimes();
    const unsubscribe = subscribeToLapTimes();

    return unsubscribe; // Cleanup subscription on unmount
  }, []);

  const processLapTimes = (data) => {
    const newLapTimes = data.map((record) => {
      const baseRow = {
        id: `${record.tag}-${record.location}-${record[`${record.location}.numLaps`]}`,
        tag: record.tag,
        numLaps: record[`${record.location}.numLaps`],
        location: record.location,
        lapTime: record[`${record.location}.lapTime`]?.toFixed(3),
        updated: record[`${record.location}.rtcTime`],
      };

      if (record.location === "L07") {
        let row = {
          ...baseRow,
          h1_corner: (
            record["L07.lapTime"] -
            (record["L07.rtcTime"] - record["L04.rtcTime"]) / 1000
          ).toFixed(3),
          h2_corner: ((record["L07.rtcTime"] - record["L04.rtcTime"]) / 1000).toFixed(3),
          type: "corner_split",
        };
        row["valid"] = (row.h1_corner > 0 && row.h2_corner > 0 && baseRow.lapTime > 0)
        return row;
      }

      return baseRow;
    });


    setLapTimes((prev) => {
      const merged = [...newLapTimes, ...prev];
      /*
      Group laptimes together wehere L04.rtcTime and L07.rtcTime both lie within a 1 second window
      */
      const groupedLapTimes = [];

      merged.forEach((current) => {
        const existingGroup = groupedLapTimes.find((group) => {
          const l04Time = group.find((item) => item.location === "L04")?.updated;
          const l07Time = group.find((item) => item.location === "L07")?.updated;
          return (
            l04Time &&
            l07Time &&
            Math.abs(l04Time - current.updated) <= 1000 &&
            Math.abs(l07Time - current.updated) <= 1000
          );
        });

        if (existingGroup) {
          existingGroup.push(current);
        } else {
          groupedLapTimes.push([current]);
        }
      });

      /*
      For each grouped laptime, find the 3rd rtcTime at L04 and L07
      */
      groupedLapTimes.forEach((group) => {
        const l04Times = group.filter((item) => item.location === "L04").map((item) => item.updated);
        const l07Times = group.filter((item) => item.location === "L07").map((item) => item.updated);

        if (l04Times.length >= 3 && l07Times.length >= 3) {
          const thirdL04Time = l04Times.sort((a, b) => a - b)[2];
          const thirdL07Time = l07Times.sort((a, b) => a - b)[2];

          group.forEach((item) => {
            if (item.location === "L04") {
              item.thirdRtcTime = thirdL04Time;
            } else if (item.location === "L07") {
              item.thirdRtcTime = thirdL07Time;
            }
          });
        }
      });
      const positionData = groupedLapTimes.map((group) => {
        const l04Times = group.filter((item) => item.location === "L04").sort((a, b) => a.updated - b.updated);
        const l07Times = group.filter((item) => item.location === "L07").sort((a, b) => a.updated - b.updated);

        if (l04Times.length >= 4 && l07Times.length >= 1) {
          const firstL04 = l04Times[0];
          const secondL04 = l04Times[1];
          const thirdL04 = l04Times[2];
          const fourthL04 = l04Times[3];
          const firstL07 = l07Times[0];

          const timeDiffL04L07 = (firstL07.updated - firstL04.updated) / 1000;
          const timeDiffL07PrevL04 = (firstL07.updated - l04Times[l04Times.length - 1].updated) / 1000;

          return {
            firstTag: firstL04.tag,
            secondTag: secondL04.tag,
            thirdTag: thirdL04.tag,
            fourthTag: fourthL04.tag,
            timeDiffL04L07: timeDiffL04L07.toFixed(3),
            timeDiffL07PrevL04: timeDiffL07PrevL04.toFixed(3),
          };
        }
        return null;
      }).filter(item => item !== null);

      console.log("positionData", positionData);
      return positionData;

      /*const flattenedGroupedLapTimes = groupedLapTimes.flat();


      const uniqueRows = merged.filter(
        (row, index, self) => index === self.findIndex((r) => r.id === row.id)
      );
      uniqueRows.sort((a, b) => b.updated - a.updated);
      console.log("uniqueRows", uniqueRows)
      return uniqueRows;*/
    });
  };

  const toggleColumn = (column) => {
    setVisibleColumns((prev) => ({ ...prev, [column]: !prev[column] }));
  };

  const handleStart = () => {
    console.log("Start button clicked!");
    // Add your start logic here
  };

  const filteredLapTimes = lapTimes.filter((record) => {
    if (
      selectedTags.length > 0 &&
      !selectedTags.includes(record.tag)
    ) {
      return false;
    }
    if (
      selectedCategories.length > 0 &&
      !tags.some(
        (tag) =>
          tag.tag === record.tag &&
          selectedCategories.includes(tag.category)
      )
    ) {
      return false;
    }
    return true;
  });

  const getTagName = (tag) => {
    const tagObj = tags.find((t) => t.tag === tag);
    return tagObj ? tagObj?.name + " (" + tag + ")" : tag; // Use the name if available, otherwise fallback to tag
  };


  return (
    <div>
      <h1>Team Pursuit</h1>

      <LapTimesTable tags={tags} lapTimes={filteredLapTimes} visibleColumns={visibleColumns} />
    </div>
  );
};

const LapTimesTable = ({ tags, lapTimes, visibleColumns }) => {
  const getTagName = (tag) => {
    const tagObj = tags.find((t) => t.tag === tag);
    return tagObj ? tagObj?.name + " (" + tag + ")" : tag; // Use the name if available, otherwise fallback to tag
  };

  return (
    <table>
      <thead>
        <tr>
          <th>Distance</th>
          <th>1st</th>
          <th>2nd</th>
          <th>3rd</th>
          <th>4th</th>
          <th>Half LapTime 3rd</th>
          <th>TotalTime 3rd</th>
        </tr>
      </thead>
      <tbody>
        {lapTimes.map((record) => (
          "valid" in record && record["valid"]
            && ((visibleColumns.corner_split && record.h1_corner)
              || (visibleColumns.straight_split && record.h1_straight)
              || (visibleColumns.sprint_split && record.m0_m200)) ?
            <tr
              key={record.id}
              style={{

              }}
            >
              <td>{getTagName(record.tag)}</td>
              <td>{record.numLaps}</td>
              {visibleColumns.corner_split && <td>{record.h1_corner}</td>}
              {visibleColumns.corner_split && <td>{record.h2_corner}</td>}
              {visibleColumns.straight_split && <td>{record.h1_straight}</td>}
              {visibleColumns.straight_split && <td>{record.h2_straight}</td>}
              {visibleColumns.sprint_split && <td>{record.m0_m200}</td>}
              {visibleColumns.sprint_split && <td>{record.m200_m100}</td>}
              {visibleColumns.sprint_split && <td>{record.m100_m0}</td>}
              <td>{record.lapTime}</td>
            </tr> : null
        ))}
      </tbody>
    </table>
  );
};

export default TeamPursuit;
