import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import { SprintProvider } from "./SprintContext";
import { supabase } from "./supabase";

import LiveSupabase from "./Pages/LiveSupabase";
import TagsSupabase from "./Pages/TagsSupabase";
import Home from "./Pages/Home";
import Sprint from "./Pages/Sprint";
import Settings from "./Pages/Settings";
import TeamPursuit from "./Pages/TeamPursuit";
import VideoBrowser from "./Pages/VideoBrowser";
import VideoAutoplayLast from "./Pages/VideoAutoplayLast";
import RegistrationForm from "./Pages/Registration";
import withSite from "./withSite";
import Welcome from "./Pages/Welcome";
import End from "./Pages/End";

import { fetchSessions } from "./Pages/Registration";
import { getSessionPhase, SESSION_PHASE, getCurrentSession } from "./Utils/SessionUtils";

import './App.css';

export const SITE_TAG = 'ghent_eddymerckx';
// export const SCREEN_MAP = {
//     'live': '/',
//     'tags': '/tags',
//     'home': '/home',
//     'sprint': '/sprint',
//     'settings': '/settings',
//     'video': '/video',
//     'video_autoplay_last': '/video_autoplay_last',
//     'video_browser': '/video_browser',
//     'register/gent': '/register/gent',
//     'register/zolder': '/register/zolder',
//     'welcome': '/welcome',
//     'end': '/end',
// };

export const SCREEN_MAP = {
    'live': '/',
    'sprint': '/sprint',
    'teampursuit': "/teampursuit"
}; // Screens that the user can select

const mapScreenTag = (screenTag) => Object.keys(SCREEN_MAP).includes(screenTag) ? SCREEN_MAP[screenTag] : '/';

const sites = {
    'ghent_eddymerckx': { name: 'Wielercentrum Eddy Merckx' },
    'zolder_velodroom': { name: 'Velodroom Zolder' },
  };

const RegistrationWithSiteGent = withSite(RegistrationForm, sites['ghent_eddymerckx']);
// const RegistrationWithSiteZolder = withSite(RegistrationForm, sites['zolder_velodroom']);

function App() {
    const navigate = useNavigate();
    const location = useLocation();

    const [screenTag, setScreenTag] = useState('/');
    const [currentSession, setCurrentSession] = useState(null);
    const [sessionPhase, setSessionPhase] = useState(false); // start, mid, end

    useEffect(() => {
        const fetchScreenConfig = async () => {
            const { data, error } = await supabase
            .from("screen_configuration")
            .select("*")
            .eq("site", SITE_TAG);

            if (error) {
                console.error(error);
                return;
            }

            if (data.length === 0) {
                console.error("No screen configuration found for site", SITE_TAG);
                return;
            }

            setScreenTag(mapScreenTag(data[0].screen_tag));
        };

        fetchScreenConfig();
    }, [])

    useEffect(() => {
        const taskListener = supabase.channel('screen_configuration').on(
            "postgres_changes",
            { event: "UPDATE", schema: "public", table: "screen_configuration" },
            (payload) => {
                const { site, screen_tag: screenTag } = payload.new;

                if (site !== SITE_TAG) return;
                setScreenTag(mapScreenTag(screenTag));
            },
        ).subscribe();

        return () => taskListener.unsubscribe();
    }, [navigate]);

    // New effect to check current session and manage automatic redirection
    useEffect(() => {
        const checkCurrentSession = async () => {
            try {
                // Fetch all sessions
                const sessions = await fetchSessions();
                
                // Find current session
                const activeSession = getCurrentSession(sessions);
                
                if (activeSession) {
                    setCurrentSession(activeSession);
                    
                    // Get current phase
                    const phase = getSessionPhase(activeSession);
                    setSessionPhase(phase);

                    // console.log('Current session:', activeSession);
                    // console.log('Current phase:', phase);
                    
                    if (activeSession?.is_group_event || false) // Only redirect if it's a group session
                        // Handle navigation based on session phase
                        if (phase === SESSION_PHASE.START) {
                            // First 15 minutes - show welcome screen
                            if (location.pathname !== '/welcome') {
                                navigate('/welcome');
                            }
                        } else if (phase === SESSION_PHASE.END) {
                            // Last 5 minutes - show end screen
                            if (location.pathname !== '/end') {
                                navigate('/end');
                            }
                        }
                        else if (phase !== null) {
                            // Show user activated screen
                            if (location.pathname !== screenTag) {
                                navigate(screenTag);
                            }
                        } 
                } else {
                    // No current session
                    setCurrentSession(null);
                    setSessionPhase(null);
                }
            } catch (err) {
                console.error('Error checking sessions:', err);
            }
        };

        // Check immediately and then every minute
        checkCurrentSession();
        const interval = setInterval(checkCurrentSession, 60 * 1000);
        
        return () => clearInterval(interval);
    }, [navigate, location.pathname, screenTag]);
    
    useEffect(() => {
      const validPaths = Object.values(SCREEN_MAP);

      // Redirect to user selected screen (via `/settings`) if not on the correct screen
      if (validPaths.includes(location.pathname) && screenTag !== location.pathname) {
          navigate(screenTag);
      }
    }, [navigate, screenTag, location.pathname]);

  return (
  <div className="App">
      <main style={{ width: "100%" }}>
      <Routes>
        <Route path="/" element={<LiveSupabase />}/>
        <Route path="/tags" element={<TagsSupabase />} />
        <Route path="/home" element={<Home />} />
        <Route path="/sprint" element={<SprintProvider><Sprint /></SprintProvider>} />
        <Route path="/settings" element={<Settings />}/>
        <Route path="/teampursuit" element={<SprintProvider><TeamPursuit /></SprintProvider>} />
        <Route path="/sprint_fixed" element={<SprintProvider><Sprint /></SprintProvider>} />
        <Route path="/video_browser" element={<VideoBrowser />} />
        <Route path="/video_autoplay_last" element={<VideoAutoplayLast />} />
        <Route path="/register/gent" element={<RegistrationWithSiteGent />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/end" element={<End />} />

        {/* <Route path="/register/zolder" element={<RegistrationWithSiteZolder />} /> */}
      </Routes> 
      </main>
     
    </div>
  );
}

//

export default App;
