import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Container,
  CircularProgress,
  Alert,
  Paper
} from '@mui/material';
import { supabase } from '../supabase';
import { fetchSessions } from './Registration';
import { getCurrentSession } from '../Utils/SessionUtils';
import SportVlaanderenHeader from '../Components/common/Headers';

/**
 * End screen component that displays a closing video during the final minutes of a session
 */
const End = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentSession, setCurrentSession] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);

  useEffect(() => {
    const loadSessionData = async () => {
      try {
        setLoading(true);
        
        // Fetch available sessions
        const sessions = await fetchSessions();
        
        // Get current active session
        const activeSession = getCurrentSession(sessions);
        
        if (activeSession) {
          setCurrentSession(activeSession);
          
          // Get the recap video URL from the session
          if (activeSession.recap_url) {
            setVideoUrl(activeSession.recap_url);
          } else {
            console.log('No recap video URL found for current session');
          }
        }
      } catch (err) {
        console.error('Error loading session data:', err);
        setError('Er is een fout opgetreden bij het laden van de video.');
      } finally {
        setLoading(false);
      }
    };

    loadSessionData();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md">
        <Alert severity="error" sx={{ mt: 4 }}>{error}</Alert>
      </Container>
    );
  }

  if (!currentSession) {
    return (
      <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
        <SportVlaanderenHeader />
        
        <Box sx={{ 
          flexGrow: 1, 
          display: 'flex', 
          flexDirection: 'column',
          p: 4,
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Geen actieve sessie gevonden
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', bgcolor: 'black' }}>
      <SportVlaanderenHeader />
      
      <Box sx={{ 
        flexGrow: 1, 
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        p: 3
      }}>
        <Typography variant="h3" component="h1" gutterBottom sx={{ mb: 4, color: 'white' }}>
          {currentSession.name} 
        </Typography>
        <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4, color: 'white' }}>
          Bedankt voor je deelname!
        </Typography>
        
        {videoUrl ? (
          <Box sx={{ width: '100%', maxWidth: '1200px', height: 'auto', maxHeight: '70vh' }}>
            <video
              autoPlay
              loop
              muted
              playsInline
              controls
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            >
              <source src={videoUrl} type="video/mp4" />
              Je browser ondersteunt geen video weergave.
            </video>
          </Box>
        ) : (
          <Paper elevation={3} sx={{ p: 4, maxWidth: '800px', mx: 'auto' }}>
            <Typography variant="h5" align="center">
              Geen video beschikbaar voor deze sessie
            </Typography>
          </Paper>
        )}
      </Box>
    </Box>
  );
};

export default End;