import React from 'react';
import { 
  Box, 
  Typography, 
  Paper,
  Grid
} from '@mui/material';
import QRCode from 'react-qr-code';

/**
 * Displays session instructions and registration QR code
 * 
 * @param {Object} props
 * @param {Object} props.currentSession - Currently active session
 */
const InstructionsPanel = ({ currentSession }) => {
  const [urlRegister, setUrlRegister] = React.useState('');

  React.useEffect(() => {
    setUrlRegister(`${window.location.origin}/register/gent`);
  }, []);

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 4}}>
      <Typography variant="h4" gutterBottom align='center'>
        Enkele belangrijke richtlijnen
      </Typography>
          <Box component="ul" sx={{ pl: 2 }}>
            <Typography component="li" variant="body1" paragraph fontSize = "1.2rem">
              Registreer je transponder voor deze sessie via onderstaande QR-code of volgende link: <strong>{urlRegister}</strong>
            </Typography>
            <Typography component="li" variant="body1" paragraph fontSize = "1.2rem">
              Volg de aanwijzingen van de begeleider tijdens de hele sessie.
            </Typography>
            <Typography component="li" variant="body1" paragraph fontSize = "1.2rem">
              Respecteer de andere deelnemers en houd voldoende afstand.
            </Typography>
          </Box>


          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            mt: 5
          }}>
            <Typography variant="h5" gutterBottom align='center'>
              Registratie
            </Typography>

            {currentSession && (
              <QRCode 
                value={urlRegister}
                size={300}
              />
            )}
          </Box>


    </Paper>
  );
};

export default InstructionsPanel;