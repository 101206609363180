/**
 * Utility functions for managing session phases and timing
 */

// Session phases constants
export const SESSION_PHASE = {
    START: 'start',  // First 15 minutes of session
    MID: 'mid',      // Middle portion of session
    END: 'end'       // Last 5 minutes of session
  };

// Debug time configuration
let useDebugTime = false;
let debugTime = new Date('2025-03-22T13:05:00Z');

/**
 * Gets the current time, using debug time if enabled
 * @returns {Date} - Current time or debug time
 */
export const getCurrentTime = () => {
  return useDebugTime ? new Date(debugTime) : new Date();
};

/**
 * Sets a specific debug time for testing
 * @param {string|Date} time - Debug time to use
 * @param {boolean} enable - Whether to enable debug time (default: true)
 */
export const setDebugTime = (time, enable = true) => {
  debugTime = time instanceof Date ? time : new Date(time);
  useDebugTime = enable;
  console.log(`Debug time ${useDebugTime ? 'enabled' : 'disabled'}: ${debugTime}`);
  return debugTime;
};

/**
 * Disables debug time, returning to real-time
 */
export const disableDebugTime = () => {
  useDebugTime = false;
  console.log('Debug time disabled, using real time');
};
  
/**
 * Determines the current phase of a session based on timing
 * @param {Object} session - Session object with startTime and endTime properties
 * @param {Object} options - Optional configuration parameters
 * @param {number} options.startPhaseMinutes - Minutes for start phase (default: 15)
 * @param {number} options.endPhaseMinutes - Minutes for end phase (default: 5)
 * @returns {string|null} - Current session phase or null if not in active session
 */
export const getSessionPhase = (session, options = {}) => {
  if (!session) return null;
  
  const { 
    startPhaseMinutes = 5, 
    endPhaseMinutes = 5 
  } = options;
  
  const now = getCurrentTime();
  const sessionStart = new Date(session.startTime);
  const sessionEnd = new Date(session.endTime);
  
  // Check if we're in a valid session timeframe
  if (now < sessionStart || now > sessionEnd) return null;
  
  // Calculate phase thresholds
  const startPhaseEnd = new Date(sessionStart.getTime() + startPhaseMinutes * 60 * 1000);
  const endPhaseStart = new Date(sessionEnd.getTime() - endPhaseMinutes * 60 * 1000);
  
  if (now <= startPhaseEnd) {
    return SESSION_PHASE.START;
  } else if (now >= endPhaseStart) {
    return SESSION_PHASE.END;
  } else {
    return SESSION_PHASE.MID;
  }
};

/**
 * Finds the current active session from a list of sessions
 * @param {Array} sessions - List of session objects
 * @returns {Object|null} - Current session or null if none is active
 */
export const getCurrentSession = (sessions) => {
  if (!sessions || sessions.length === 0) return null;
  
  // const now = new Date();
  const now = getCurrentTime();
  
  return sessions.find(session => 
    new Date(session.startTime) <= now && 
    new Date(session.endTime) >= now
  ) || null;
};

/**
 * Finds the next upcoming session after the current one
 * @param {Array} sessions - List of session objects
 * @param {Object} currentSession - Currently active session
 * @returns {Object|null} - Next session or null if none exists
 */
export const getNextSession = (sessions, currentSession) => {
  if (!sessions || sessions.length === 0 || !currentSession) return null;
  
  const sessionEnd = new Date(currentSession.endTime);
  
  return sessions.find(session => new Date(session.startTime) > sessionEnd) || null;
};