import React from 'react';
import { Box } from '@mui/material';

const SportVlaanderenHeader = () => {
  return (
    <Box 
      sx={{ 
        display: "flex", 
        alignItems: "center", 
        justifyContent: "center", 
        padding: "0px 8px",
        mb: 0,
        mt: 2,
        fontSize: "2rem"
      }}
    >
      <h1 style={{ margin: "0px" }}>
        <span style={{color: "#006fb7"}}>SPORT.VLAANDEREN</span>{' '}
        <span style={{color: "#38b3e8"}}>GENT</span>
      </h1>
    </Box>
  );
};

export default SportVlaanderenHeader;