import React, { useState, useEffect, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { 
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Alert,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete
} from '@mui/material';
import { supabase } from '../supabase';
import { useSite } from "../SiteContext";
import tags from "../config/tags.json";
import SportVlaanderenHeader from '../Components/common/Headers';
import { getCurrentTime } from '../Utils/SessionUtils';

const populateTagOptionsJson = () => {
  const tagOptions = Object.keys(tags.ghent_eddymerckx);
  // Append values of the dictionary to the list, so user can input either
  Object.values(tags.ghent_eddymerckx).forEach((value) => tagOptions.push(value));
  console.log('Tag list', tagOptions);
  return Array.from(new Set(tagOptions));
};

export const populateTagOptionsSupabase = async (site) => {
  const siteToCategory = {
    'Wielercentrum Eddy Merckx': 'gent',
    'Velodroom Zolder': 'zolder'
  };

  const category = siteToCategory[site] || 'gent'; // Default gent if no match
  
  console.log('Category:', category);

  const { data, error } = await supabase
    .from('tags')
    .select('tag, name')
    .ilike('category', `%${category}%`);

  if (error) {
      console.error('Error fetching tags:', error);
      return [];
    }

  // Create a mapping of display names to actual tag IDs, and a list of all tags to display in the autocomplete
  const tagMappings = {};
  const displayOptions = [];

  data.forEach(item => {
    if (item.tag) displayOptions.push(item.tag);
    if (item.name) displayOptions.push(item.name);

    if (item.tag && item.name) {
      tagMappings[item.name] = item.tag;
      tagMappings[item.tag] = item.tag; // Use the tag as the value if no name is provided
    }
  });

  // Sort the tags
  displayOptions.sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }));

  return {
    options: Array.from(new Set(displayOptions)),
    mappings: tagMappings
  };
};

/**
 * Fetches the three most relevant sessions from the schedule table.
 * @returns {Promise<Array>} - A promise that resolves to an array of sessions.
 */
export const fetchSessions = async () => {
  const now = getCurrentTime().toISOString();

  const { data, error } = await supabase
    .from('schedule')
    .select('*')
    .order('startTime', { ascending: true });

  if (error) {
    console.error('Error fetching sessions:', error);
    return [];
  }

  const todaysSessions = data.filter(session => 
    new Date(session.startTime).toLocaleDateString() === new Date(now).toLocaleDateString()
  );

  const before = todaysSessions.filter(session => new Date(session.startTime) < new Date(now));
  const current = todaysSessions.filter(session => new Date(session.startTime) <= new Date(now) && new Date(session.endTime) >= new Date(now));
  const after = todaysSessions.filter(session => new Date(session.startTime) > new Date(now));
  // console.log(before, current, after);

  // before shall contain the last session before the current session, and none if the current session is the first
  // return [
  //   ...before.slice(0), // Previous session
  //   ...current.slice(0, 1), // Current session
  //   ...after.slice(0, 1) // Next session
  // ];
  return todaysSessions;
};

const formatDate = (date) => {
  const d = new Date(date) || getCurrentTime();
  const month = `${d.getMonth() + 1}`.padStart(2, '0');
  const day = `${d.getDate()}`.padStart(2, '0');
  const year = d.getFullYear();
  return `${year}-${month}-${day}`;
};

/**
 * Registration form component for athlete registration
 * Handles form submission and validation using React Hook Form
 */
const RegistrationForm = () => {
  // Form handling with React Hook Form
  const site = useSite();

  const { 
    register, 
    handleSubmit,
    formState: { errors },
    reset,
    control
  } = useForm();
  
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [optOutStats, setOptInStats] = useState(false);
  const [optOutVideo, setOptInVideo] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState('');
  const [tagOptions, setTagOptions] = useState([]);
  const [tagMappings, setTagMappings] = useState({});
  
  useEffect(() => {
    // Load tags from Supabase based on site
    const loadTags = async () => {
      const result = await populateTagOptionsSupabase(site.name);
      setTagOptions(result.options);
      setTagMappings(result.mappings);
      console.log('Site name:', site.name); 
      console.log('Tag options from Supabase:', result.options);
      console.log('Tag mappings:', result.mappings);
    };
    
    loadTags();

    const loadSessions = async () => {
      const sessions = await fetchSessions();
      setSessions(sessions);
      console.log('Sessions:', sessions);
    };

    loadSessions();
  }, [site.name]);

  const onSubmit = async (data) => {
    // Get the actual tag ID if user entered a display name
    const actualTag = tagMappings[data.mylapsTag] || data.mylapsTag;

    // If the entered tag is not among the known options, confirm with the user.
    if (!tagMappings[data.mylapsTag]) {
      const confirmCustom = window.confirm(
        `De MyLaps tag "${data.mylapsTag}" is niet gekend in onze database. Gebruik je je eigen tag?`
      );
      if (!confirmCustom) {
        return;
      }
    }

    setLoading(true);
    setSubmitError(null);
    setSubmitSuccess(false);

    try {
      // Insert registration data into Supabase
      const insertData = {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        tag: actualTag,
        site: site.name,
        session_name: selectedSession.name,
        session_id: selectedSession.id,
        session_date: formatDate(new Date()),
        opt_out_stats: optOutStats,
        opt_out_video: optOutVideo,
        created_at: new Date().toISOString()
      };
  
      // Include birth_date only if it is provided
      if (data.birthDate) {
        insertData.birth_date = data.birthDate;
      }
      
      const { error } = await supabase
        .from('users')
        .insert([insertData]);
        console.log(selectedSession)

      if (error) throw error;

      setSubmitSuccess(true);
      reset(); // Clear form after successful submission
      setOptInStats(false);
      setOptInVideo(false);
      setSelectedSession('');
    } catch (err) {
      setSubmitError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SportVlaanderenHeader />

      <Container maxWidth="sm">
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center' }}>
            Registratie MyLaps Transponder
          </Typography>

          {submitError && (<Alert severity="error" sx={{ mb: 2 }}>{submitError}</Alert>)}
          {submitSuccess && (<Alert severity="success" sx={{ mb: 2 }}>Registratie gelukt!</Alert>)}

          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {/* First Name Field */}
              <TextField
                label="Voornaam *"
                {...register('firstName', { required: 'Voornaam is verplicht' })}
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
              />

              {/* Last Name Field */}
              <TextField
                label="Achternaam *"
                {...register('lastName', { required: 'Achternaam is verplicht' })}
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
              />

              {/* Alias Field (Optional) */}
              <TextField
                label="Alias (naam tijdens sessie)"
                {...register('alias')}
              />

              {/* Email Field */}
              <TextField
                label="E-mail *"
                type="email"
                {...register('email', { 
                  required: 'E-mail is verplicht',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Ongeldig e-mailadres'
                  }
                })}
                error={!!errors.email}
                helperText={errors.email?.message}
              />

              {/* Birth Date Field */}
              <TextField
                label="Geboortedatum"
                type="date"
                InputLabelProps={{ shrink: true }}
                {...register('birthDate')}
                error={!!errors.birthDate}
                helperText={errors.birthDate?.message}
              />

              {/* MyLaps Tag Field */}
              <Controller
                name="mylapsTag"
                control={control}
                rules={{ required: 'MyLaps tag is verplicht' }}
                render={({ field, fieldState: { error } }) => {
                  const handleBlur = (e) => {
                    field.onBlur(e);
                  //   if (field.value && !availableTags.includes(field.value)) {
                  //     const confirmed = window.confirm(
                  //       `De tag "${field.value}" is niet gekend in onze database. Gebruik je je eigen tag?`
                  //     );
                  //     if (confirmed) {
                  //       setLastValidTag(field.value);
                  //     } else {
                  //       // Revert to last valid tag if available; otherwise clear
                  //       field.onChange(lastValidTag || "");
                  //     }
                  //   }
                  };

                  return (
                    <Autocomplete
                      freeSolo
                      options={tagOptions}
                      inputValue={field.value || ""}
                      onInputChange={(event, newInputValue) => {
                        field.onChange(newInputValue);
                      }}
                      renderInput={(params) => (
                        <TextField 
                          {...params}
                          label="MyLaps Tag *"
                          error={!!error}
                          helperText={error ? error.message : ""}
                          onBlur={handleBlur}
                        />
                      )}
                    />
                  );
                }}
              />

              <FormControl fullWidth>
                <InputLabel id="session-label">Sessie *</InputLabel>
                <Select
                  labelId="session-label"
                  value={selectedSession}
                  onChange={(e) => setSelectedSession(e.target.value)}
                  label="Sessie"
                >
                  {sessions.map((session) => (
                    <MenuItem key={session.id} value={session}>
                      {session.name} ({new Date(session.startTime).toLocaleString()} - {new Date(session.endTime).toLocaleString()})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Instagram Handle */}
              <TextField
                label="Instagram Handle"
                {...register('instagram')}
                placeholder='@voorbeeld
                '
              />

              {/* Asterisk indicates required fields */}
              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                * Verplicht in the vullen
              </Typography>

              <FormGroup sx={{ mt: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={optOutStats}
                      onChange={(e) => setOptInStats(e.target.checked)}
                    />
                  }
                  label="Ik wens een rapport met statistieken te ontvangen na de sessie."
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={optOutVideo}
                      onChange={(e) => setOptInVideo(e.target.checked)}
                    />
                  }
                  label="Ik ga akkoord dat beeldmateriaal van mij wordt opgenomen voor het genereren van een overzichtsvideo van de sessie."
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={optOutVideo}
                      onChange={(e) => setOptInVideo(e.target.checked)}
                    />
                  }
                  label="Ik ga akkoord dat beelden waar ik in verschijn gedeeld mogen worden op sociale media (vul je Instagram naam in als je getagged wilt worden)."
                />
              </FormGroup>
              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                sx={{ mt: 2 }}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Registreer'
                )}
              </Button>
            </Box>
          </form>
        </Box>
      </Container>
      </>
  );
};

export default RegistrationForm;