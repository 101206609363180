import React from 'react';
import { 
  Box, 
  Typography, 
  Paper,
  Chip,
  Grid
} from '@mui/material';
import { getCurrentTime } from '../../Utils/SessionUtils';

const getSessionStatus = (session) => {
  const now = getCurrentTime();
  const startTime = new Date(session.startTime);
  const endTime = new Date(session.endTime);
  
  if (now >= startTime && now <= endTime) {
    return 'active';
  } else if (now > endTime) {
    return 'past';
  } else {
    return 'upcoming';
  }
};

const SessionItem = ({ session, isCurrentSession }) => {
  const status = getSessionStatus(session);
  
  // Format times
  const startTime = new Date(session.startTime).toLocaleTimeString([], { 
    hour: '2-digit', 
    minute: '2-digit', 
    hour12: false 
  });
  const endTime = new Date(session.endTime).toLocaleTimeString([], { 
    hour: '2-digit', 
    minute: '2-digit', 
    hour12: false 
  });
  
  // Status-based styling and labels
  const statusConfig = {
    active: { 
      color: 'success.main',
      bgColor: 'rgba(76, 175, 80, 0.2)',
      label: 'Nu bezig',
      chipColor: 'success'
    },
    past: { 
      color: 'grey.500',
      bgColor: 'transparent',
      label: 'Afgelopen',
      chipColor: 'default'
    },
    upcoming: { 
      color: 'primary.main',
      bgColor: 'transparent',
      label: 'Later',
      chipColor: 'primary'
    }
  };
  
  const config = statusConfig[status];
  
  return (
    <Box 
      sx={{
        p: 2,
        borderRadius: 1,
        border: '1px solid',
        borderColor: status === 'active' ? config.color : 'divider',
        backgroundColor: status === 'active' ? config.bgColor : 'transparent',
        position: 'relative',
        '&::before': status === 'active' ? {
          content: '""',
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          width: '4px',
          backgroundColor: config.color,
          borderTopLeftRadius: '4px',
          borderBottomLeftRadius: '4px'
        } : {}
      }}
    >
      <Grid container alignItems="center">
        <Grid item xs={5}>
          <Typography 
            variant="h6" 
            component="h3" 
            sx={{ 
              fontWeight: status === 'active' ? 'bold' : 'normal',
              color: status === 'past' ? 'text.secondary' : 'text.primary'
            }}
          >
            {session.name}
          </Typography>
        </Grid>
      
        <Grid item xs={4} sx={{ textAlign: 'center' }}>
          <Typography 
            variant="h6" 
            color={status === 'past' ? 'text.disabled' : 'text.secondary'} 
          >
            {startTime} - {endTime}
          </Typography>
        </Grid>
        
        <Grid item xs={3} sx={{ textAlign: 'right' }}>
          <Chip 
            label={config.label}
            color={config.chipColor}
            size="medium"
          />
        </Grid>
      </Grid>

      
      {session.description && (
        <Typography 
          variant="body2" 
          color={status === 'past' ? 'text.disabled' : 'text.primary'} 
          sx={{ mt: 1 }}
        >
          {session.description}
        </Typography>
      )}
    </Box>
  );
};

/**
 * Displays a list of today's sessions with the current one highlighted
 * 
 * @param {Object} props
 * @param {Array} props.sessions - List of all sessions for today
 * @param {Object} props.currentSession - Currently active session object
 */
const SessionsList = ({ sessions, currentSession }) => {
  if (!sessions || sessions.length === 0) {
    return (
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          Sessies vandaag
        </Typography>
        <Typography variant="body1" sx={{ textAlign: 'center', py: 2 }}>
          Geen sessies gepland voor vandaag.
        </Typography>
      </Paper>
    );
  }

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
      <Typography variant="h5" gutterBottom>
        Sessies vandaag
      </Typography>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        gap: 1,
        maxHeight: '500px',
        overflowY: 'auto'
      }}>
        {sessions.map((session) => (
          <SessionItem 
            key={session.id}
            session={session}
            isCurrentSession={currentSession && currentSession.id === session.id}
          />
        ))}
      </Box>
    </Paper>
  );
};

export default SessionsList;