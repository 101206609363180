import React, { useState, useEffect } from 'react';
import { 
    Container, 
    Typography, 
    Box, 
    Paper,
    List, 
    ListItem,
    ListItemText,
    ListItemAvatar, 
    Avatar,
    Divider,
    CircularProgress,
    Alert,
    Grid
} from '@mui/material';
import { supabase } from '../supabase';
import { useSite } from "../SiteContext";
import { fetchSessions } from './Registration';
import { getSessionPhase, SESSION_PHASE, getCurrentSession, getNextSession, getCurrentTime } from '../Utils/SessionUtils';

import SessionsList from '../Components/welcome/SessionsList';
import InstructionsPanel from '../Components/welcome/InstructionsPanel';
import ParticipantsList from '../Components/welcome/ParticipantsList';
import SportVlaanderenHeader from '../Components/common/Headers';

/**
 * Fetches registered users for a specific session
 * @param {string} sessionName - Name of the session
 * @param {string} sessionDate - Date of the session in YYYY-MM-DD format
 * @returns {Promise<Array>} - A promise that resolves to an array of users
 */
const fetchRegisteredUsers = async (sessionName, sessionDate) => {
    const { data, error } = await supabase
    .from('users')
    .select('*')
    .eq('session_name', sessionName)
    .eq('session_date', sessionDate);

    if (error) {
    console.error('Error fetching registered users:', error);
    return [];
    }

    return data || [];
};

/**
 * Welcome screen component that displays session information and registered users
 */
const Welcome = () => {
    const site = useSite();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sessions, setSessions] = useState([]);
    const [currentSession, setCurrentSession] = useState(null);
    const [registeredUsers, setRegisteredUsers] = useState([]);
    const [showInstructions] = useState(true);
    const [sessionPhase, setSessionPhase] = useState(null);
    const REFRESH_INTERVAL = 10000; // 10 seconds

    // Format date for database queries
    const formatDate = (date) => {
    const d = new Date(date) || getCurrentTime();
    const month = `${d.getMonth() + 1}`.padStart(2, '0');
    const day = `${d.getDate()}`.padStart(2, '0');
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
    };

    // Add a new useEffect for periodic refresh of participants
    useEffect(() => {
        let intervalId;
        
        // Only set up polling if we have a current session
        if (currentSession) {
            const refreshParticipants = async () => {
                try {
                    const users = await fetchRegisteredUsers(
                        currentSession.name,
                        formatDate(new Date(currentSession.startTime))
                    );
                    // Only update if the number of participants changed or other data changed
                    if (JSON.stringify(users) !== JSON.stringify(registeredUsers)) {
                        setRegisteredUsers(users);
                    }
                } catch (err) {
                    console.error('Error refreshing participants:', err);
                }
            };
            
            // Initial fetch
            refreshParticipants();
            
            // Set up interval for periodic polling
            intervalId = setInterval(refreshParticipants, REFRESH_INTERVAL);
        }
        
        // Clean up interval on component unmount or when currentSession changes
        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [currentSession]);

    // Load current session and registered users
    useEffect(() => {
        const loadSessionData = async () => {
            try {
                setLoading(true);
                
                // Fetch available sessions
                const sessions = await fetchSessions();
                setSessions(sessions);
                console.log(sessions)
                
                const activeSession = getCurrentSession(sessions);
                
                if (activeSession) {
                    // Determine session phase
                    const phase = getSessionPhase(activeSession);
                    setSessionPhase(phase);
                    
                    // If in END phase, look for next session
                    if (phase === SESSION_PHASE.END) {
                        const nextSession = getNextSession(sessions, activeSession);
                        
                        // Use next session if available, otherwise fallback to current
                        const sessionToShow = nextSession || activeSession;
                        setCurrentSession(sessionToShow);
                        
                        // Fetch registered users for the selected session
                        const users = await fetchRegisteredUsers(
                            sessionToShow.name,
                            formatDate(new Date(sessionToShow.startTime))
                        );
                        setRegisteredUsers(users);
                    } else {
                        // Not in end phase, use current session
                        setCurrentSession(activeSession);
                        
                        // Fetch registered users for current session
                        const users = await fetchRegisteredUsers(
                            activeSession.name,
                            formatDate(new Date(activeSession.startTime))
                        );
                        setRegisteredUsers(users);
                    }
                }
            } catch (err) {
                console.error('Error loading session data:', err);
                setError('Er is een fout opgetreden bij het laden van de sessiegegevens.');
            } finally {
                setLoading(false);
            }
            };

        loadSessionData();
        }, []); 

    if (loading) {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
        </Box>
    );
    }

    if (error) {
    return (
        <Container maxWidth="md">
        <Alert severity="error" sx={{ mt: 4 }}>{error}</Alert>
        </Container>
    );
    }

    if (!currentSession) {
        return (
          <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            <SportVlaanderenHeader />
            
            <Box sx={{ 
              flexGrow: 1, 
              display: 'flex', 
              flexDirection: 'column',
              p: 4,
              justifyContent: 'center',
              maxWidth: '900px',
              mx: 'auto',
              width: '100%'
            }}>
              <Typography variant="h3" component="h1" gutterBottom sx={{ mb: 4, textAlign: 'center' }}>
                Programma
              </Typography>
              
              <SessionsList
                sessions={sessions}
                currentSession={null}
                sx={{ 
                  '& .MuiPaper-root': {
                    maxWidth: '100%'
                  }
                }}
              />
            </Box>
          </Box>
        );
      }

    return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
        <SportVlaanderenHeader />
            
        <Box sx={{ 
            flexGrow: 1, 
            display: 'flex', 
            flexDirection: 'column',
            p: 3
        }}>
            <Typography variant="h3" component="h1" gutterBottom sx={{mb: 3, textAlign: 'center'}}>
                Welkom {currentSession.name}!
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>    
                    {showInstructions && (
                        <InstructionsPanel 
                            currentSession={currentSession}
                            sx={{ flexGrow: 1 }}
                         />
                    )}
                </Grid>

                <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <SessionsList
                            sessions={sessions}
                            currentSession={currentSession}
                        />

                        <ParticipantsList
                            participants={registeredUsers}
                            sx={{ flexGrow: 1, mt: 2 }}
                        />
                    </Box>  
                </Grid>
                
            </Grid>
        </Box>
    </Box>
    );
};

export default Welcome;