import React from 'react';
import { 
  Box, 
  Typography, 
  Paper,
  Avatar,
  Chip,
  Stack
} from '@mui/material';

/**
 * Gets initials from a person's name
 * @param {string} firstName - First name
 * @param {string} lastName - Last name
 * @returns {string} - Initials (up to 2 characters)
 */
const getInitials = (firstName, lastName) => {
  const firstInitial = firstName ? firstName[0] : '';
  const lastInitial = lastName ? lastName[0] : '';
  return `${firstInitial}${lastInitial}`.toUpperCase();
};

/**
 * Displays list of registered participants for the session in a compact format
 * 
 * @param {Object} props
 * @param {Array} props.participants - List of registered participants
 */
const ParticipantsList = ({ participants }) => {
  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Geregistreerde deelnemers ({participants.length})
      </Typography>
      
      {participants.length === 0 ? (
        <Typography variant="body1" sx={{ textAlign: 'center', py: 3 }}>
          Er zijn nog geen deelnemers geregistreerd voor deze sessie.
        </Typography>
      ) : (
        <Box sx={{ 
          display: 'flex', 
          flexWrap: 'wrap', 
          gap: 1, 
          maxHeight: '300px', 
          overflowY: 'auto',
          py: 1
        }}>
          {participants.map((user, index) => {
            const initials = getInitials(user.first_name, user.last_name);
            return (
              <Chip
                key={user.id || index}
                label={user.alias || `${initials}`}
                variant="outlined"
                sx={{ 
                  m: 0.5,
                  '& .MuiChip-label': {
                    maxWidth: '150px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }
                }}
              />
            );
          })}
        </Box>
      )}
    </Paper>
  );
};

export default ParticipantsList;