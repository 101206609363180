import { createClient } from '@supabase/supabase-js';

// Replace these with your Supabase project details
const SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL;
const SUPABASE_ANON_KEY = process.env.REACT_APP_SUPABASE_ANON_KEY;

if (!SUPABASE_URL || !SUPABASE_ANON_KEY) {
    console.error('Missing Supabase configuration. Check your .env file.');
    console.error('SUPABASE_URL:', SUPABASE_URL);
    console.error('SUPABASE_ANON_KEY:', SUPABASE_ANON_KEY ? '(exists)' : '(missing)');
  }

const SUPABASE_ACCESS_TOKEN = process.env.REACT_APP_SUPABASE_ACCESS_TOKEN
const SUPABASE_REFRESH_TOKEN = process.env.REACT_APP_SUPABASE_REFRESH_TOKEN

export const supabase = createClient(SUPABASE_URL, SUPABASE_ANON_KEY);
supabase.auth.setSession({access_token: SUPABASE_ACCESS_TOKEN, refresh_token: SUPABASE_REFRESH_TOKEN})
