import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { FINISH_TAG, SprintContext } from '../../SprintContext';

const styles = {
    header: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        padding: '8px 12px',
    },
        stickyRow: {
            position: 'sticky',
            top: '48hi px',
            zIndex: 2,
    },
};

const SprintTable = memo(({ laps, singular }) => {
    const tableRef = useRef(null);
    const intervalRef = useRef(null);

    const [isScrolling, setIsScrolling] = useState(true);

    const { findTagMapping } = useContext(SprintContext);

    // Higher is faster.
    // Don't set <= 1. For some reason it breakes the scrolling.
    const scrollSpeed = 1;

    const startScrolling = () => {
        if (tableRef.current && isScrolling && !singular) {
            const container = tableRef.current;

            intervalRef.current = setInterval(() => {
                if (container.scrollHeight > container.clientHeight) {
                    container.scrollTop += scrollSpeed;

                    if (container.scrollTop + container.clientHeight - 30  > container.scrollHeight) {
                        console.log(container.scrollTop + container.clientHeight, container.scrollHeight);
                        clearInterval(intervalRef.current); 
                        setIsScrolling(false);

                        // After 1 second, restart scrolling from the top
                        setTimeout(() => {
                            container.scrollTop = 0;
                            setIsScrolling(true);
                            startScrolling();
                        }, 1000);
                    }
                    if (container.scrollTop + container.clientHeight == container.scrollHeight) {
                        clearInterval(intervalRef.current); 
                        // After 1 second, restart scrolling from the top
                        setTimeout(() => {
                            container.scrollTop = 0;
                            console.log(container.clientHeight  > container.scrollHeight)
                            setIsScrolling();
                            if(container.clientHeight - 50 > container.scrollHeight){
                                startScrolling();
                            }
                        }, 1000);   
                    }          

                }
            }, 40); // 40ms for ~25fps
        }
    };

    useEffect(() => {
        startScrolling();
        return () => clearInterval(intervalRef.current);
    }, [isScrolling, singular]); 

    if (singular && laps.length === 0) return null;

    return (
        <div className='scroll-container' style={{ maxHeight: '100%' }} ref={tableRef}>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        <th style={styles.header}>Plaats</th>
                        <th style={styles.header}>Renner</th>
                        <th style={{ ...styles.header, textAlign: 'end' }}>Start {'>'} 200m</th>
                        <th style={{ ...styles.header, textAlign: 'end' }}>200m {'>'} 100m</th>
                        <th style={{ ...styles.header, textAlign: 'end' }}>100m {'>'} Finish</th>
                        <th style={{ ...styles.header, textAlign: 'end' }}>Finish</th>
                    </tr>
                </thead>
                <tbody>
                    {laps.sort((a, b) => a[FINISH_TAG] - b[FINISH_TAG]).map((lap, index) => (
                        <tr key={index} style={index === 0 ? styles.stickyRow : {}}>
                            <td>{singular ? singular : index + 1}</td>
                            <td style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '500px', width: '500px'}}>
                                {findTagMapping(lap.tag)}
                            </td>
                            <td style={{textAlign: 'end'}}>{lap['L03'].toFixed(3)}</td>
                            <td style={{textAlign: 'end'}}>{(lap['L05'] - lap['L03']).toFixed(3)}</td>
                            <td style={{textAlign: 'end'}}>{(lap[FINISH_TAG] - lap['L05']).toFixed(3)}</td>
                            <td style={{textAlign: 'end'}}>{lap[FINISH_TAG].toFixed(3)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
});

export default SprintTable;