import React, { createContext, useContext } from 'react';

const SiteContext = createContext();

export const SiteProvider = ({ site, children }) => {
  return (
    <SiteContext.Provider value={site}>
      {children}
    </SiteContext.Provider>
  );
};

export const useSite = () => {
  return useContext(SiteContext);
};