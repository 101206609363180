import { useEffect, useState, useCallback, useRef, createContext } from "react";
import { supabase } from "./supabase";

export const FINISH_TAG = 'L01';
export const loops = Array.from({ length: 7 }, (_, i) => `L0${i + 1}`);

export const loopDistances = {
    L01: 0,
    L02: 35,
    L03: 50,
    L04: 107,
    L05: 150,
    L06: 160,
    L07: 232
}

const dictDiff = (dict1, dict2) => {
    const changed = {};
    for (const key in dict1) {
        if (dict1[key] !== dict2[key]) {
            changed[key] = [dict1[key], dict2[key]];
        }
    }
    return changed;
};

const SprintContext = createContext();

const SprintProvider = ({ children }) => {
    const [laps, setLaps] = useState([]);
    const [lapBuilder, setLapBuilder] = useState({});
    const [fastestLap, setFastestLap] = useState(null);
    const [lastUpdate, setLastUpdate] = useState({});
    const [targetRider, setTargetRider] = useState({});
    const [tags, setTags] = useState([]);

    const lastUpdateRef = useRef(lastUpdate);
    const lapBuilderRef = useRef(lapBuilder);

    const postgresInsertedLapCallback = (payload) =>
        setLaps((prev) => [...prev, { ...payload.new, start: new Date(payload.new.start).getTime() }]);

    const postgresUpdatedTargetsCallback = (payload) => {
        const { target_tag: targetTag } =  payload.new;
        console.log(payload)

        let tag = null;
        if (targetTag !== null) {
            tag = targetTag.includes(',') ? targetTag.split(',')[0] : targetTag;
        }

        setTargetRider(tag);
    }

    const postgresChangesCallback = useCallback((payload) => {
        const { new: record } = payload;
        const { tag: tagname, location: updatedLoop } = record;

        //if (tagname !== 'SK16113') return;
        //if (!tagname.startsWith('S')) return;

        const updatedRtcTime = record[`${updatedLoop}.rtcTime`];

        if (tagname in lastUpdateRef.current) {
            const diff = dictDiff(lastUpdateRef.current[tagname], record);
            if ('location' in diff) {
                console.log(`[${tagname}] Changed location from ${diff.location[0]} to ${diff.location[1]}`);
            }

            if (updatedLoop === FINISH_TAG) {
                setLapBuilder((prev) => ({
                    ...prev,
                    [tagname]: { 
                        start: updatedRtcTime 
                    } 
                }));
            } else {
                setLapBuilder((prev) => ({
                    ...prev,
                    [tagname]: {
                        ...prev[tagname],
                        [updatedLoop]: updatedRtcTime,
                    },
                }));
            }
        } else if (updatedLoop === FINISH_TAG) {
            setLapBuilder((prev) => ({
                ...prev,
                [tagname]: {
                    start: updatedRtcTime,
                    [updatedLoop]: updatedRtcTime,
                },
            }));
        }

        setLastUpdate({
            ...lastUpdateRef.current,
            [tagname]: record,
        });
    }, [lastUpdate]);

    const findTagMapping = (tag) => {
        const mapping = tags.find((t) => t.tag === tag);
        return mapping ? mapping.name : tag;    
    }

    useEffect(() => {
        const taskListenerLapUpdates = supabase.channel('laptimes').on(
            "postgres_changes",
            { event: "*", schema: "public", table: "laptimes" },
            (payload) => postgresChangesCallback(payload),
        ).subscribe();


        const taskListenerLaps = supabase.channel('laps').on(
            "postgres_changes",
            { event: "INSERT", schema: "public", table: "laps" },
            (payload) => postgresInsertedLapCallback(payload),
        ).subscribe();
        
        const taskListenerTargets = supabase.channel('screen_configuration').on(
            "postgres_changes",
            { event: "*", schema: "public", table: "screen_configuration", filter: 'id=eq.1' },
            (payload) => postgresUpdatedTargetsCallback(payload),
        )

        return () => {
            taskListenerLapUpdates.unsubscribe();
            taskListenerLaps.unsubscribe();
            taskListenerTargets.unsubscribe();
        }
    },[]);

    useEffect(() => {
        lastUpdateRef.current = lastUpdate;
    }, [lastUpdate])

    useEffect(() => {
        lapBuilderRef.current = lapBuilder;
    }, [lapBuilder])

    useEffect(() => {
        if (laps.length === 0) return;

        const newLap = laps[laps.length - 1];

        // Ignore start signal
        if (newLap.tag === '9992') return;
        if (fastestLap === null || newLap[FINISH_TAG] < laps[fastestLap][FINISH_TAG]) {
            setFastestLap(laps.length - 1);
        }
    }, [laps.length])

    useEffect(() => {
        const fetchTags = async () => {
            const { data } = await supabase.from("tags").select("*");
            setTags(data || []);
        };

        const fetchLaps = async () => {
            const { data } = await supabase.from("laps").select("*");
            const mappedData = data.map(lap => ({
                ...lap,
                start: new Date(lap.start).getTime(),
            }));

            setLaps(mappedData || []);

            // determine fastest lap from database
            let flap = null;
            for(let i = 0; i < mappedData.length; i++) {
                const lap = mappedData[i];
                if(flap !== null)
                    console.log(lap[FINISH_TAG], mappedData[flap][FINISH_TAG])
                if (flap === null || lap[FINISH_TAG] < mappedData[flap][FINISH_TAG]) {
                    flap = i;
                }
               
            }

            setFastestLap(flap);
        }

        fetchTags();
        fetchLaps();
    }, []);

    return <SprintContext.Provider value={{ laps, fastestLap, lapBuilder, targetRider, findTagMapping }}>{children}</SprintContext.Provider>;
}

export { SprintProvider, SprintContext };